import axios from "axios";

export const baseURL = "https://scripts.systems.leadific.io";
//export const baseURL = "http://localhost:4444";

const DefaultHeaders = {};

export const httpRequest = async (url, method, headers, data) => {
  try {
    const response = await axios.request({
      baseURL,
      url,
      method,
      headers: {
        ...DefaultHeaders,
        ...headers,
      },
      data,
    });
    console.log({response})
    return response.data;
  } catch (err) {
    console.log({err});
    return { status: false, message: `${JSON.stringify(err)}` };
  }
};
