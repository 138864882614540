import React, { useEffect } from "react";
import Iframe from "react-iframe";
import {httpRequest} from '../api/axios'
import Routes from '../api/routes'


function getFaviconEl() {
  return document.getElementById("favicon");
}

export const IframeScreen = ({ link = '', allow  = '' }) => {

        const favicon = getFaviconEl();

          // get settings
          const getSettings = async ()  => {
    
            const response = await httpRequest(Routes.GET_SETTINGS, 'post', null, {
                domain : window.location.hostname
            })
    
            if(response){
                if(response?.data?.whiteLabelTitle) {  document.title = response?.data?.whiteLabelTitle }

                if(response?.data?.favicon) {  
                  favicon.href = response?.data?.favicon
                }
                else if(response?.data?.image) {
                  favicon.href = response?.data?.image
                }
                
            }
        }

  useEffect(()=>{

    getSettings()

    let iframe = window.document.getElementById("iframe");
    iframe.allow="geolocation microphone camera midi encrypted-media vr";
  })

  return(
    <>
      <Iframe
       frameborder="0"
      //  sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"
        url={link}
        allow={allow}
        name={window.location.hostname}
        width="100%"
        height={window.innerHeight}
        id="iframe"
        className="iframe"
        display="initial"
        position="relative"
        loading="eager"
      />
    </>
  )
}

export default IframeScreen;
